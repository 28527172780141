import { Typography } from '@snack-uikit/typography';
import { numberFormat } from 'src/components/Calculator/utils/numberFormat';
import {
  CurrentPricePeriod,
  PlansStatus,
} from 'src/components/TariffPlans/types';

import s from './styles.module.scss';

type PriceInCardProps = {
  price: number;
  currentPricePeriod: CurrentPricePeriod;
  planStatus?: PlansStatus;
  discountInPercent?: number;
  isFreeTier?: boolean;
};

function getCurrentPricePeriodText(currentPricePeriod: CurrentPricePeriod) {
  switch (currentPricePeriod) {
    case 'priceHourNds':
      return ' в час с НДС';
    case 'priceDayNds':
      return ' в день с НДС';
    case 'priceMonthNds':
    default:
      return ' в месяц с НДС';
  }
}

function PriceInCard({
  price,
  currentPricePeriod,
  planStatus,
  discountInPercent,
  isFreeTier,
}: PriceInCardProps) {
  if (planStatus === 'error') {
    return (
      <div className={s.priceFree}>
        <Typography.SansTitleL>&nbsp;</Typography.SansTitleL>
      </div>
    );
  }

  const isDiscountExist =
    typeof discountInPercent === 'number' &&
    discountInPercent > 0 &&
    discountInPercent <= 100;

  const totalPrice = isDiscountExist
    ? price - (price / 100) * discountInPercent
    : price;

  if (isFreeTier && totalPrice === 0)
    return (
      <div className={s.priceFree}>
        <Typography.SansTitleL className={s.priceTitle}>
          <span>Free tier</span>
        </Typography.SansTitleL>
      </div>
    );

  return (
    <div className={s.price}>
      <Typography.SansTitleL>
        {numberFormat(totalPrice)} ₽
      </Typography.SansTitleL>
      <div className={s.pricePeriod}>
        {isDiscountExist && (
          <span className={s.pricePeriodDiscount}>{numberFormat(price)} ₽</span>
        )}
        <Typography.SansBodyS>
          {getCurrentPricePeriodText(currentPricePeriod)}
        </Typography.SansBodyS>
      </div>
    </div>
  );
}

export { PriceInCard };
