import { RichText } from 'src/components/RichText';
import { ArrowOut } from 'src/uikit/icons/ContentIcons/General/ArrowOut';
import { Link } from 'src/uikit/Link';

import { CardProps } from '../../types';
import s from './PreviewCard.module.scss';

export function PreviewCard({
  title,
  text,
  previewImage,
  slug = '',
}: Pick<CardProps, 'title' | 'text' | 'previewImage' | 'slug'>) {
  return (
    <Link
      className={s.previewCard}
      href={slug}
      data-qa="benefits-preview-card"
      id={`benefits-preview-card-${title}`}
      analyticsOptions={{
        action: slug,
        clickZone: 'body',
        clickElement: 'cardclick',
        clickContent: title,
        uniqueId: `benefits-preview-card-${title}`,
        transitionType: 'inside-link',
      }}
    >
      {previewImage && (
        <picture className={s.previewCardImageWrapper}>
          <img
            src={previewImage}
            width={592}
            height={330}
            alt={title}
            className={s.previewCardImage}
          />
        </picture>
      )}
      <section className={s.previewCardDescription}>
        <h4
          className={s.previewCardTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {text && <RichText className={s.previewCardText} richText={text} />}

        <span className={s.previewCardSubtitle}>
          Подробнее <ArrowOut className={s.previewCardSubtitleIcon} />
        </span>
      </section>
    </Link>
  );
}
