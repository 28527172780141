import { numberFormat } from 'src/components/Calculator/utils/numberFormat';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoStorage,
  PlanIdStorage,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdStorage;
  price: number;
  description: string;
  apiPut: PlanEvoStorage['data']['apiPut'];
  apiPost: PlanEvoStorage['data']['apiPost'];
  storageSize: PlanEvoStorage['data']['storageSize'];
  storageType: PlanEvoStorage['data']['storageType'];
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

const storageTypeObj = {
  cold: 'Холодное',
  ice: 'Ледяное',
  standart: 'Стандартное',
  single: 'Однозонное',
};

function EvoStoragePlanCard({
  planId,
  price,
  description,
  apiPut,
  apiPost,
  storageSize,
  storageType,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS,
    productType:
      planId === 'free-tier'
        ? 'evolutionStorageS3FreeTier'
        : 'evolutionStorageS3',
  });

  const characteristics = [
    {
      title: 'Тип хранилища',
      value: `${storageTypeObj[storageType]}`,
    },
    {
      title: 'Объем хранилища',
      value: `${numberFormat(storageSize)} ГБ`,
    },
    {
      title: 'PUT-операции',
      value: `${numberFormat(apiPut)} шт`,
    },
    {
      title: 'POST-операции',
      value: `${numberFormat(apiPost)} шт`,
    },
  ];

  return (
    <PlanCard
      productType={
        planId === 'free-tier'
          ? 'evolutionStorageS3FreeTier'
          : 'evolutionStorageS3'
      }
      isFreeTier={planId === 'free-tier'}
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoStoragePlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionStorageS3.map(
    (plan) => {
      return (
        <EvoStoragePlanCard
          key={plan.planId}
          planId={plan.planId}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          apiPut={plan.data.apiPut}
          apiPost={plan.data.apiPost}
          storageSize={plan.data.storageSize}
          storageType={plan.data.storageType}
          onClickOnConnect={() =>
            connectProduct(
              plan.planId === 'free-tier'
                ? 'evolutionStorageS3FreeTier'
                : 'evolutionStorageS3',
              plan,
            )
          }
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionStorageS3"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoStoragePlansSwiper };
