import { themeVars } from '@sbercloud/figma-tokens-web';
import { ButtonFunction } from '@snack-uikit/button';
import { Divider } from '@snack-uikit/divider';
import { ChevronRightSVG } from '@snack-uikit/icons';
import { List } from '@snack-uikit/list';
import { Tabs } from '@snack-uikit/tabs';
import { Typography } from '@snack-uikit/typography';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';

import s from './ProductChanger.module.scss';

type ListItemType = {
  title: string;
  desc: string;
  link: string;
  icon: string;
  id: ProductTabID;
};

enum ProductTabID {
  EvoCloudServerMainPage = 'evoCloudServerMainPage',
  EvoS3MainPage = 'evoS3MainPage',
  EvoKuberMainPage = 'evoKuberMainPage',
  EvoBareMetalMainPage = 'evoBareMetalMainPage',
}

const mobileTabs: {
  id: ProductTabID;
  content: { option: string };
}[] = [
  {
    id: ProductTabID.EvoCloudServerMainPage,
    content: { option: 'Evolution Compute' },
  },
  {
    id: ProductTabID.EvoS3MainPage,
    content: { option: 'Evolution Object Storage' },
  },
  {
    id: ProductTabID.EvoKuberMainPage,
    content: { option: 'Evolution Managed Kubernetes' },
  },
];

const products: {
  id: ProductTabID;
  content: ReactNode;
  slug: ProductTabID;
}[] = [
  {
    id: ProductTabID.EvoCloudServerMainPage,
    content: (
      <ListItem
        icon="/svg/components/Calculator/vm.svg"
        title="Evolution Compute"
        desc="Виртуальные машины для развертывания сервисов и приложений"
        link="/products/evolution-compute"
        id={ProductTabID.EvoCloudServerMainPage}
      />
    ),
    slug: ProductTabID.EvoCloudServerMainPage,
  },
  {
    id: ProductTabID.EvoS3MainPage,
    content: (
      <ListItem
        icon="/svg/components/Calculator/s3.svg"
        title="Evolution Object Storage"
        desc="Масштабируемое хранилище S3 для всех типов данных"
        link="/products/evolution-object-storage"
        id={ProductTabID.EvoS3MainPage}
      />
    ),
    slug: ProductTabID.EvoS3MainPage,
  },
  {
    id: ProductTabID.EvoKuberMainPage,
    content: (
      <ListItem
        icon="/svg/components/Calculator/kubernetes.svg"
        title="Evolution Managed Kubernetes"
        desc="Управление контейнерными приложениями в кластере Kubernetes"
        link="/products/evolution-managed-kubernetes"
        id={ProductTabID.EvoKuberMainPage}
      />
    ),
    slug: ProductTabID.EvoKuberMainPage,
  },
  // {
  //   id: 'evoBareMetalMainPage',
  //   content: (
  //     <ListItem
  //       icon="/svg/components/Calculator/.svg"
  //       title="Title"
  //       desc="Description"
  //       link="/products/evolution-bare-metal"
  //     />
  //   ),
  //   slug: 'evoBareMetalMainPage',
  // },
];

function ListItem({ title, desc, icon, link, id }: ListItemType) {
  const { push } = useRouter();
  const handleMoreClick = () => {
    push(link);
  };
  const state = useTariffPlansState();
  const currentPlan = state.activePlansSet;
  const active = currentPlan === id;

  return (
    <div className={s.item}>
      <img src={icon} alt="calculator" className={s.img} />
      <div className={s.info}>
        <div className={s.text}>
          <Typography.SansTitleM className={s.title}>
            {title}
          </Typography.SansTitleM>
          {active && (
            <Typography.SansBodyM className={s.desc}>
              {desc}
            </Typography.SansBodyM>
          )}
        </div>
        {active && (
          <ButtonFunction
            label="Подробнее"
            onClick={handleMoreClick}
            icon={<ChevronRightSVG color={themeVars.sys.neutral.textLight} />}
            iconPosition="after"
          />
        )}
      </div>
    </div>
  );
}

function ProductChanger() {
  const dispatch = useTariffPlansDispatch();
  const [tabId, setTabId] = useState<ProductTabID>(
    ProductTabID.EvoCloudServerMainPage,
  );

  const handleChange = (value: string) => {
    if (tabId === value || !value) return;

    setTabId(value as ProductTabID);
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLANS_SET,
      payload: value as ProductTabID,
    });
  };

  return (
    <div className={s.products}>
      <Divider orientation="vertical" className={s.divider} />
      <List
        size="m"
        items={products}
        selection={{
          value: tabId,
          onChange: handleChange,
          mode: 'single',
        }}
        scroll
        className={s.list}
      />
      <div className={s.tabs}>
        <Tabs onChange={handleChange} value={tabId}>
          <Tabs.TabBar>
            {mobileTabs.map((item) => (
              <Tabs.Tab
                key={item.id}
                value={item.id}
                label={item.content.option}
              />
            ))}
          </Tabs.TabBar>
        </Tabs>
      </div>
    </div>
  );
}

export { ProductChanger };
