import {
  LAYOUT_TYPE,
  MatchMedia,
  useMatchMedia,
} from '@sbercloud/uikit-product-utils';

const matchMediaToLayoutType = ({
  isMobile,
  isSmallDesktop,
  isTablet,
}: MatchMedia) => {
  if (isMobile) {
    return LAYOUT_TYPE.Mobile;
  }

  if (isTablet) {
    return LAYOUT_TYPE.Tablet;
  }

  if (isSmallDesktop) {
    return LAYOUT_TYPE.DesktopSmall;
  }

  return LAYOUT_TYPE.Desktop;
};

export function useLayoutType() {
  const matchMedia = useMatchMedia();
  const layoutType = matchMediaToLayoutType(matchMedia);

  return layoutType;
}
