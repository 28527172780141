import cn from 'classnames';
import { useRouter } from 'next/router';
import { PortalModal } from 'src/layout/PortalModal';
import { Button } from 'src/uikit/Button';
import { Variant } from 'src/uikit/Button/constants';
import { CrossIcon } from 'src/uikit/icons/CrossIcon';

import s from './FormNotice.module.scss';

interface VariantProps {
  className?: string;
  title: string;

  isError?: boolean;

  isModal: boolean;
  onButtonClick?(): void;
}

interface WithButton {
  buttonText: string;
  analyticsButtonId: string;
}

interface WithoutButtons {
  buttonText?: undefined;
  analyticsButtonId?: undefined;
}

type ButtonVariant = WithButton | WithoutButtons;

interface InnerProps {
  isModal: false;
  onClose?(): undefined;
}

interface ModalProps {
  isModal: true;
  onClose(): void;
}

type FormNoticeProps = VariantProps & (InnerProps | ModalProps) & ButtonVariant;

function NoticeWrapper({
  isModal,
  children,
  onClose,
}: {
  isModal?: boolean;
  children: React.ReactNode;
  onClose?(): void;
}) {
  if (isModal && onClose)
    return (
      <PortalModal
        className={s.modal}
        withoutDefaultCloseIcon
        portalClassName={s.portal}
        onClickForClose={onClose}
      >
        <div className={s.wrapper}>
          <button onClick={onClose} className={s.closeWrapper}>
            <CrossIcon className={s.close} />
          </button>
          {children}
        </div>
      </PortalModal>
    );
  return <> {children} </>;
}

export function FormNotice({
  title,
  analyticsButtonId,
  buttonText = '',
  className = '',
  isModal,
  isError = false,
  onButtonClick,
  onClose,
}: FormNoticeProps) {
  const router = useRouter();

  const handleButtonButtonClick = () => {
    if (isError) {
      router.reload();
      return;
    }

    if (onButtonClick) {
      onButtonClick();
    } else {
      router.push('/');
    }
  };

  return (
    <NoticeWrapper onClose={onClose} isModal={isModal}>
      <div className={cn(s.root, className)}>
        <h2 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />

        {buttonText && (
          <Button
            onClick={handleButtonButtonClick}
            variant={Variant.Primary}
            data-qa="notice-button"
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: buttonText,
              uniqueId: `notice-modal-button-${analyticsButtonId}`,
              transitionType: 'inside-link',
            }}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </NoticeWrapper>
  );
}
