import { Carousel } from '@snack-uikit/carousel';

import { useTariffSwiperData } from '../../hooks/useTariffSwiperData';
import s from './TariffSwiper.module.scss';

type TariffSwiperProps = {
  slides: JSX.Element[];
  isProduct?: boolean;
};

export const TariffSwiper = ({ slides, isProduct }: TariffSwiperProps) => {
  const { itemsCount, isTablet } = useTariffSwiperData(isProduct);

  return (
    <Carousel
      className={isProduct ? s.sliderProduct : s.slider}
      showItems={itemsCount}
      scrollBy={1}
      pagination={!isProduct}
      arrows={!isTablet}
      gap={isTablet ? '16px' : '32px'}
    >
      {slides}
    </Carousel>
  );
};
