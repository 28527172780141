import { useWindowWidth } from 'src/hooks/useWindowWidth';

export const useTariffSwiperData = (isProduct?: boolean) => {
  const { windowWidth, breakpoints: pageBreakpoints } = useWindowWidth({
    defaultInnerWidth: true,
  });
  const isTablet = windowWidth && windowWidth < 1280;

  const getSlidesCount = () => {
    if (!windowWidth) return 1;

    const breakpoints = [
      { maxWidth: 500, value: 1 },
      { maxWidth: pageBreakpoints.MD, value: 2 },
      { maxWidth: pageBreakpoints.LG, value: 3 },
    ];

    const match = breakpoints.find((bp) => windowWidth < bp.maxWidth);

    return match ? match.value : isProduct ? 4 : 3;
  };

  const itemsCount = getSlidesCount();

  return { isProduct, itemsCount, isTablet };
};
