import React from 'react';
import { PortalModal } from 'src/layout/PortalModal';

import { CrossIcon } from '../../icons/CrossIcon';
import s from '../Form.module.scss';
import { FormBase } from '../FormBase';
import { FormBaseProps } from '../types';

export function ModalForm({
  modalClose,
  productTitle,
  eventCategory,
  ...props
}: FormBaseProps) {
  return (
    <>
      {modalClose && (
        <PortalModal
          className={s.modal}
          withoutDefaultCloseIcon
          portalClassName={s.portal}
          onClickForClose={modalClose}
        >
          <div className={s.wrapper}>
            <button className={s.closeWrapper} onClick={modalClose}>
              <CrossIcon className={s.close} />
            </button>
            <FormBase
              // FIXME: почему хардкод? В props так же есть dataLayer
              dataLayer={{
                event: 'custom_event',
                event_category: eventCategory || 'consultation LeadForm',
                event_action: 'formSuccess',
                event_label: productTitle ? productTitle : 'mainPage',
              }}
              {...props}
              successfulSubmissonFormAnalytics={
                props.successfulSubmissonFormAnalytics && {
                  zoneclick:
                    props.successfulSubmissonFormAnalytics.zoneclick || 'P',
                  formname: props.successfulSubmissonFormAnalytics?.formname,
                }
              }
              allFormsSentIsPopup
            />
          </div>
        </PortalModal>
      )}
    </>
  );
}
