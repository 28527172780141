import cn from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useAnalytics } from '../../../hooks/useAnalytics';
import { Checkbox } from '../Checkbox';
import s from '../Form.module.scss';
import { Input } from '../Input';
import { MultiSelectControl } from '../MultiSelect';
import { Submit } from '../Submit';
import { TextArea } from '../TextArea';
import { FormBaseProps } from '../types';
import { UserAgreementCheckboxes } from '../UserAgreement';

type Props = Pick<
  FormBaseProps,
  | 'fieldsNames'
  | 'short'
  | 'legalEntity'
  | 'multipleComment'
  | 'agreementName'
  | 'withInn'
  | 'withSubscribeForMailing'
  | 'isSubscribeForMailingRequired'
> & {
  sendFormFillAnalytics(name: string): void;
  isPhysic: boolean;
  buttonTitle: string;
};

export function FormDefault({
  fieldsNames,
  short,
  legalEntity,
  multipleComment,
  buttonTitle,
  agreementName,
  sendFormFillAnalytics,
  isPhysic,
  isSubscribeForMailingRequired,
  withSubscribeForMailing,
  withInn,
}: Props) {
  const {
    formState: { errors },
    register,
    resetField,
    setValue,
    control,
  } = useFormContext();

  const { fillFormField } = useAnalytics();

  return (
    <>
      <div className={s.double}>
        <Input
          name="name"
          label={fieldsNames?.name?.label ?? 'Имя'}
          inputType={Input.inputTypes.Text}
          validationType={Input.validations.Name}
          required
          errors={errors}
          register={register}
          resetField={resetField}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('name');
            }
          }}
        />
        <Input
          name="phone"
          inputType={Input.inputTypes.Phone}
          label={fieldsNames?.phone?.label ?? 'Телефон'}
          required
          errors={errors}
          register={register}
          resetField={resetField}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('tel');
            }
          }}
        />
      </div>

      <div
        className={cn({
          [s.double]: withInn,
        })}
      >
        <Input
          name="email"
          inputType={Input.inputTypes.Text}
          validationType={Input.validations.Email}
          label={fieldsNames?.email?.label ?? 'Электронная почта'}
          errors={errors}
          register={register}
          resetField={resetField}
          required
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('email');
            }
          }}
        />

        {withInn && (
          <Input
            label="ИНН"
            validationType={Input.validations.Inn}
            placeholder=" "
            name="inn"
            inputType={Input.inputTypes.Number}
            errors={errors}
            onChange={(event) =>
              setValue('inn', event.target.value.replace(/[^0-9]/g, ''))
            }
            className={s.input}
            register={register}
            required
            resetField={resetField}
            data-qa="section_input"
            onBlur={(event) => {
              if (event.currentTarget.value) {
                fillFormField({
                  formType: 'popup',
                  fieldName: 'ИНН',
                  popupPlace: 'body',
                  buttonId: 'partners-body',
                });
              }
            }}
          />
        )}
      </div>

      {!short && !legalEntity && (
        <Checkbox
          className={s.checkbox}
          errors={errors}
          required={false}
          register={register}
          name="isNaturalPerson"
          value="true"
          label={fieldsNames?.checkbox?.text ?? 'Я физлицо'}
        />
      )}

      {!short && (
        <>
          <div className={s.double}>
            <Input
              name="company"
              validationType={Input.validations.Company}
              inputType={Input.inputTypes.Text}
              label={fieldsNames?.company?.label ?? 'Название компании'}
              required
              errors={errors}
              register={register}
              disabled={isPhysic}
              resetField={resetField}
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('company');
                }
              }}
            />
            <Input
              name="position"
              validationType={Input.validations.Position}
              inputType={Input.inputTypes.Text}
              label={fieldsNames?.position?.label ?? 'Должность'}
              errors={errors}
              register={register}
              disabled={isPhysic}
              resetField={resetField}
              required
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('position');
                }
              }}
            />
          </div>

          {multipleComment ? (
            <MultiSelectControl
              options={multipleComment.options}
              control={control}
              name={multipleComment.name}
              label={multipleComment.label}
              errors={errors}
              required
            />
          ) : (
            <TextArea
              label={fieldsNames?.comment?.label ?? 'Комментарий'}
              name="comment"
              required={false}
              errors={errors}
              register={register}
              resetField={resetField}
              className={s.textarea}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('comment');
                }
              }}
            />
          )}
        </>
      )}

      <UserAgreementCheckboxes
        errors={errors}
        register={register}
        agreementName={agreementName}
        sendFormFillAnalytics={sendFormFillAnalytics}
        withSubscribeForMailing={withSubscribeForMailing}
        fieldsNames={{
          userAgreement: fieldsNames?.userAgreement,
          checkboxSubscribe: fieldsNames?.checkboxSubscribe,
        }}
        isSubscribeForMailingRequired={isSubscribeForMailingRequired}
      />

      <Submit>{buttonTitle}</Submit>
    </>
  );
}
