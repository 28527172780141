import { Layout as UIKitLayout } from '@sbercloud/uikit-product-site-layout';
import { Divider } from '@snack-uikit/divider';
import cn from 'classnames';
import { Subscribe } from 'src/components/Subscribe';
import { Wrapper } from 'src/components/Wrapper';

import { useLayoutType } from '../../hooks/useLayoutType';
import s from './Footer.module.scss';
import { FooterBody } from './FooterBody';
import { FooterBottom } from './FooterBottom';
import { FooterTop } from './FooterTop';
import { BODY_BLOCKS } from './mock';

type FooterProps = {
  className?: string;
  hideSubscribe?: boolean;
};

export function Footer({ className, hideSubscribe }: FooterProps) {
  const layoutType = useLayoutType();

  return (
    <UIKitLayout.Footer
      className={cn(s.footer, className)}
      layoutType={layoutType}
    >
      <Wrapper className={s.footerWrapper}>
        <FooterTop />
        <FooterBody blocks={BODY_BLOCKS} />
        {!hideSubscribe && (
          <Subscribe
            variant="inline"
            eventLabel="footer"
            title="Подпишитесь на новости про облачные технологии"
            successfulSubmissonFormAnalytics={{
              formname: 'digest',
            }}
          />
        )}
        <Divider />
        <FooterBottom />
      </Wrapper>
    </UIKitLayout.Footer>
  );
}
