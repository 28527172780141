import { JSX } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Button, ButtonProps } from 'src/snack/components/Button';

type Props = Pick<ButtonProps, 'label' | 'disabled' | 'loading'>;

export function Submit({ label = '', disabled, loading }: Props): JSX.Element {
  const { funnelFormSubmit } = useAnalytics();

  return (
    <Button
      onClick={funnelFormSubmit}
      fullWidth
      disabled={disabled}
      loading={loading}
      type="submit"
      analyticsOptions={{
        action: 'click',
        clickZone: 'body',
        clickElement: 'button',
        clickContent: label,
        uniqueId: 'submit-button',
        transitionType: 'inside-link',
      }}
      label={label}
      size="big"
    />
  );
}
