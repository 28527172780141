import cn from 'classnames';
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormResetField,
} from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { CrossIcon } from 'src/uikit/icons/CrossIcon';

import { MAX_TEXTAREA_CHARACTERS } from './constants';
import s from './TextArea.module.scss';

type BaseTextAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  TextAreaProps;

interface TextAreaProps {
  label: string;
  name: string;
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  resetField: UseFormResetField<FieldValues>;
  required: boolean;
  className?: string;
  onBlur: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function TextArea({
  label,
  name,
  errors,
  register,
  required,
  disabled,
  className = '',
  onBlur,
  maxLength,
  onChange,
  resetField,
  ...rest
}: BaseTextAreaProps) {
  const { funnelFormInput } = useAnalytics();
  const [isEmpty, setIsEmpty] = useState(true);
  const [charactersCount, setCharactersCount] = useState<number>(0);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { windowWidth } = useWindowWidth();

  const limitCharacters = maxLength ?? MAX_TEXTAREA_CHARACTERS;

  const {
    ref,
    onChange: onChangeInput,
    onBlur: onBlurInput,
    ...restOptions
  } = register(name);

  const onChangeInputHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    handleResize();
    onChangeInput(event);
    onChange && onChange(event);
    setIsEmpty(!Boolean(event.target.value));
  };

  const onBlurInputHandler = (event: FocusEvent<HTMLTextAreaElement>) => {
    onBlurInput(event);
    onBlur && onBlur(event);
  };

  const handleResize = () => {
    const { current } = textAreaRef;
    if (current) {
      current.style.height = '0px';
      current.style.height = `${current.scrollHeight}px`;
    }
  };

  const setLimitCharacters = () => {
    return `${charactersCount}/${limitCharacters} символов`;
  };

  useEffect(() => {
    handleResize();
  }, [windowWidth]);

  return (
    <div
      className={cn(s.root, className, {
        [s.error]: errors && errors[name],
        [s.empty]: isEmpty,
        [s.notRequired]: !required,
        [s.disabled]: disabled,
      })}
    >
      <div className={s.wrapper} onClick={() => funnelFormInput()}>
        <textarea
          id={name}
          ref={(event) => {
            textAreaRef.current = event;
            ref(event);
          }}
          className={s.textArea}
          disabled={disabled}
          maxLength={limitCharacters}
          data-qa="textarea"
          data-click="funnel-form-input"
          {...rest}
          {...restOptions}
          onChange={(event) => {
            setCharactersCount(event.target.value.length);
            onChangeInputHandler(event);
          }}
          onBlur={onBlurInputHandler}
        />
        <label className={s.label} htmlFor={name}>
          {label} {!isEmpty ? setLimitCharacters() : ''}
        </label>
        <CrossIcon
          className={cn(s.icon, s.iconCross)}
          onMouseDown={() => {
            resetField(name);
            setIsEmpty(true);
            setCharactersCount(0);
            handleResize();
          }}
        />
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>
          {errors[name]?.message?.toString() ?? 'Обязательное поле'}
        </span>
      )}
    </div>
  );
}
