import React from 'react';
import { FormStatus } from 'src/types';

import s from '../Form.module.scss';
import { FormNotice } from '../FormNotice';
import { NoticeType } from '../types';

export type FormNoticeBlockProps = {
  formStatus: FormStatus;
  successNotice: NoticeType;
  errorNotice: NoticeType;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
  analyticsButtonId: string;
  formVariant: 'inner' | 'modal' | 'hero';
  showNotice: boolean;
};

export function FormNoticeBlock({
  formStatus,
  analyticsButtonId,
  successNotice,
  errorNotice,
  setFormStatus,
  formVariant,
  showNotice,
}: FormNoticeBlockProps) {
  const notice = formStatus === 'success' ? successNotice : errorNotice;

  if ((formStatus !== 'success' && formStatus !== 'error') || !showNotice)
    return null;

  return (
    <>
      {formVariant === 'modal' ? (
        <FormNotice
          title={notice?.title}
          buttonText={notice?.buttonTitle || ''}
          isModal={false}
          className={s.modalNotice}
          onButtonClick={() => setFormStatus('idle')}
          analyticsButtonId={analyticsButtonId}
          isError={formStatus === 'error'}
        />
      ) : (
        <FormNotice
          title={notice?.title || 'old'}
          buttonText={notice?.buttonTitle || ''}
          isModal={false}
          analyticsButtonId={analyticsButtonId}
          isError={formStatus === 'error'}
        />
      )}
    </>
  );
}
