import { FieldDecorator } from '@snack-uikit/fields';
import {
  Checkbox as SnackCheckbox,
  CheckboxProps as SnackCheckboxProps,
} from '@snack-uikit/toggles';
import { JSX, useId } from 'react';
import { Controller } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import s from './Checkbox.module.scss';

type CheckboxBase = SnackCheckboxProps & {
  name: string;
  label: string | JSX.Element;
};

type CheckboxNotRequired = CheckboxBase & {
  required: false;
  requiredText?: undefined;
};

type CheckboxRequired = CheckboxBase & {
  required: true;
  requiredText?: string;
};

type CheckboxProps = CheckboxNotRequired | CheckboxRequired;

export function Checkbox({
  name,
  required,
  requiredText,
  disabled,
  label,
  value,
  checked,
  onChange,
  className,
  ...rest
}: CheckboxProps) {
  const { funnelFormInput, clickAnalytics } = useAnalytics();
  const checkBoxId = useId();

  const handleClick = (title: string | JSX.Element) => {
    clickAnalytics({
      action: 'click',
      clickZone: 'body',
      clickElement: 'buttonclick',
      clickContent: `checkbox-${title}`,
      uniqueId: `checkbox-${title}`,
      transitionType: 'inside-link',
    });
  };

  return (
    <Controller
      name={name}
      rules={{
        required: required
          ? requiredText || valSchema.default?.required
          : false,
      }}
      render={({ field, fieldState }) => (
        <FieldDecorator
          error={fieldState.error?.message?.toString()}
          disabled={disabled}
          size="m"
          className={className}
          {...rest}
        >
          <label htmlFor={checkBoxId} className={s.label}>
            <SnackCheckbox
              id={checkBoxId}
              disabled={disabled}
              checked={checked}
              {...field}
              value={value}
              onClick={() => handleClick(label)}
              onChange={(checked) => {
                onChange && onChange(checked);
                funnelFormInput();
                field.onChange(checked);
              }}
              data-test-id="checkbox"
              data-click="funnel-form-input"
            />

            <span
              className={s.text}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          </label>
        </FieldDecorator>
      )}
    />
  );
}
