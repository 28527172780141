import cn from 'classnames';
import { Button } from 'src/uikit/Button';
import { ArrowOut } from 'src/uikit/icons/ContentIcons/General/ArrowOut';
import { Link } from 'src/uikit/Link';

import { ButtonsProps } from '../types';
import s from './Buttons.module.scss';

export function Buttons({
  buttons,
  onButtonsClick,
  classNameLink,
}: ButtonsProps) {
  if (!buttons) {
    return null;
  }
  return (
    <>
      {buttons.map(({ title, link }, index) => {
        if (link) {
          return (
            <Link
              key={`${title}_${index}`}
              href={link}
              isDefault
              rightIcon={<ArrowOut className={s.linkIcon} />}
              className={cn(s.link, classNameLink)}
              target="_blank"
              analyticsOptions={{
                action: link,
                clickZone: 'body',
                clickElement: 'button',
                clickContent: title,
                uniqueId: `benefits-link-${title}`,
                transitionType: 'inside-link',
              }}
            >
              {title}
            </Link>
          );
        }

        if (!(onButtonsClick && onButtonsClick[index])) {
          return null;
        }

        return (
          <Button
            key={`${title}_${index}`}
            className={s.button}
            onClick={onButtonsClick[index] as () => {}}
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: title,
              uniqueId: `benefits-button-${title}`,
              transitionType: 'inside-link',
            }}
          >
            {title}
          </Button>
        );
      })}
    </>
  );
}
