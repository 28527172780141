import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '../Checkbox';
import s from '../Form.module.scss';
import { Input } from '../Input';
import { MultiSelectControl } from '../MultiSelect';
import { Submit } from '../Submit';
import { FormBaseProps } from '../types';
import { UserAgreementCheckboxes } from '../UserAgreement';

type Props = Pick<
  FormBaseProps,
  'fieldsNames' | 'short' | 'legalEntity' | 'multipleComment' | 'agreementName'
> & {
  sendFormFillAnalytics(name: string): void;
  isPhysic: boolean;
  buttonTitle: string;
};

export function FormEducation({
  fieldsNames,
  short,
  legalEntity,
  multipleComment,
  buttonTitle,
  agreementName,
  sendFormFillAnalytics,
  isPhysic,
}: Props) {
  const {
    formState: { errors },
    register,
    resetField,
    control,
  } = useFormContext();

  return (
    <>
      <div className={s.double}>
        <Input
          name="name"
          label={fieldsNames?.name?.label ?? 'Имя'}
          inputType={Input.inputTypes.Text}
          validationType={Input.validations.Name}
          required
          errors={errors}
          register={register}
          resetField={resetField}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('name');
            }
          }}
        />
        <Input
          name="surname"
          label="Фамилия"
          inputType={Input.inputTypes.Text}
          validationType={Input.validations.Surname}
          required
          errors={errors}
          register={register}
          resetField={resetField}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('surname');
            }
          }}
        />
      </div>

      <div className={s.double}>
        <Input
          name="email"
          inputType={Input.inputTypes.Text}
          validationType={Input.validations.Email}
          label={fieldsNames?.email?.label ?? 'Электронная почта'}
          errors={errors}
          register={register}
          resetField={resetField}
          required
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('email');
            }
          }}
        />

        <Input
          name="phone"
          inputType={Input.inputTypes.Phone}
          label={fieldsNames?.phone?.label ?? 'Телефон'}
          required
          errors={errors}
          register={register}
          resetField={resetField}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('tel');
            }
          }}
        />
      </div>

      {!short && !legalEntity && (
        <Checkbox
          className={s.checkbox}
          errors={errors}
          required={false}
          register={register}
          name="isNaturalPerson"
          value="true"
          label={fieldsNames?.checkbox?.text ?? 'Я физлицо'}
        />
      )}

      {!short && (
        <>
          <div className={s.double}>
            <Input
              name="company"
              validationType={Input.validations.Company}
              inputType={Input.inputTypes.Text}
              label={fieldsNames?.company?.label ?? 'Название компании'}
              required
              errors={errors}
              register={register}
              disabled={isPhysic}
              resetField={resetField}
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('company');
                }
              }}
            />
            <Input
              name="position"
              validationType={Input.validations.Position}
              inputType={Input.inputTypes.Text}
              label={fieldsNames?.position?.label ?? 'Должность'}
              errors={errors}
              register={register}
              disabled={isPhysic}
              resetField={resetField}
              required
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('position');
                }
              }}
            />
          </div>

          {multipleComment && (
            <MultiSelectControl
              options={multipleComment.options}
              control={control}
              name={multipleComment.name}
              label={multipleComment.label}
              errors={errors}
              required
            />
          )}
        </>
      )}

      <UserAgreementCheckboxes
        errors={errors}
        register={register}
        agreementName={agreementName}
        sendFormFillAnalytics={sendFormFillAnalytics}
        fieldsNames={{
          userAgreement: fieldsNames?.userAgreement,
          checkboxSubscribe: fieldsNames?.checkboxSubscribe,
        }}
      />

      <Submit>{buttonTitle}</Submit>
    </>
  );
}
