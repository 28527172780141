globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"tQqDEb3nJpDj5aSmUcE4J"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_SPACE === 'production' ||
  process.env.NEXT_PUBLIC_SPACE === 'stage'
) {
  Sentry.init({
    dsn: 'https://3c083c90c72a46d7963d3c15e7958eed@sentry.sbercloud.tech/92',
    environment: process.env.NEXT_PUBLIC_SPACE,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    normalizeDepth: 4,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  });
}
