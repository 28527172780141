import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { CaseProps, ClientSlideProps } from 'src/components/Carousel/types';
import { HeroProps } from 'src/components/IndexPage/Hero';
import { Seo } from 'src/components/Seo';
import { TariffPlans } from 'src/components/TariffPlans';
import { Wrapper } from 'src/components/Wrapper';
import { backendHost, consoleRedirectUrl } from 'src/global';
import { useFormatConsoleUrl } from 'src/hooks/useFormatConsoleUrl';
import { Layout } from 'src/layout/index';
import { organizationMicroData } from 'src/scripts';
import {
  type Case,
  type Catalog,
  type ResponseCases,
  type ResponseCatalog,
  type ResponseMainSlider,
} from 'src/types/backendContent';
import { Benefits } from 'src/uikit/Benefits';
import { Button } from 'src/uikit/Button';
import { ClientCasesProps } from 'src/uikit/Carousels/CarouselBase/slides/ClientCasesSlide';
import { MainSlideProps } from 'src/uikit/Carousels/CarouselBase/slides/MainSlide';
import { ClientCasesCarousel } from 'src/uikit/Carousels/ClientCasesCarousel';
import { MainCarousel } from 'src/uikit/Carousels/MainCarousel';
import { CatalogBlock } from 'src/uikit/CatalogBlock';
import { DemoSectionWithTabs } from 'src/uikit/DemoSection/DemoSectionWithTabs';
import { Form } from 'src/uikit/Form';
import { Clouds } from 'src/uikit/icons/ContentIcons/TechIcons/Clouds/Clouds';
import { Datacenter } from 'src/uikit/icons/ContentIcons/TechIcons/DataCenterIcons/Datacenter';
import { Admin } from 'src/uikit/icons/ContentIcons/TechIcons/General/Admin';
import { File } from 'src/uikit/icons/ContentIcons/TechIcons/General/File';
import { RocketFly } from 'src/uikit/icons/ContentIcons/TechIcons/General/RocketFly';
import { Ruble } from 'src/uikit/icons/ContentIcons/TechIcons/General/Ruble';
import { Shield } from 'src/uikit/icons/ContentIcons/TechIcons/General/Shield';
import { Support } from 'src/uikit/icons/ContentIcons/TechIcons/General/Support';
import { fetchOrThrow } from 'src/utils/fetchOrThrow';
import { withErrorThrowing } from 'src/utils/withErrorThrowing';

import s from './Index.module.scss';

interface MockIndexData {
  hero: Omit<HeroProps, 'cards'>;
  solutions: {
    title: string;
  };
  cases: CaseProps[];
  clients: {
    title: string;
    slides: ClientSlideProps[];
  };
  videoBlock?: {
    title: string;
    videos: { id: string; link: string }[];
    tabs: { id: string; title: string }[];
  };
}

const MockIndex: MockIndexData = {
  hero: {
    title: 'Облачные сервисы и AI-технологии ',
    description:
      'Снижаем цену прогресса, создавая фундамент для инноваций наших клиентов.',
    video:
      'https://cdn.cloud.ru/backend/video/main-page/hero_banner_retina.mp4',
    image: 'https://cdn.cloud.ru/backend/images/first_frame.png',
  },
  solutions: {
    title: 'Выбирайте решения для бизнеса',
  },
  cases: [
    {
      id: 'case1',
      title:
        'Институт искусственного интеллекта AIRI разместил ИТ-инфраструктуру в облаке Cloud.ru',
      text: 'Исследовательская работа началась в первые три месяца после открытия',
      buttonTitle: 'Читать кейс',
      link: '/cases/institut-airi-v-oblake-sbercloud',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/cases/case-1.png',
    },
    {
      id: 'case2',
      title:
        'Крупный производитель средств защиты растений прогнозирует урожаи зерновых',
      text: 'Точность прогноза более 90% благодаря разработке AI Consulting',
      link: '/cases/proizvoditel-sredstv-zashchity-rastenij-ispolzuet-razrabotku-ai-consulting',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/cases/case-2.png',
    },
    {
      id: 'case3',
      title:
        'Сервис «Работа.ру» обучил языковую модель на базе ML Space и суперкомпьютера Christofari Neo',
      text: 'Команда сервиса сократила time-to-market языковой модели HRBert в 10 раз и в результате улучшила качество услуг',
      link: '/cases/rabota-ru-obuchila-hrbert-na-ml-space',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/cases/case-3.png',
    },
    {
      id: 'case4',
      title: '«Пятерочка» сэкономила четверть бюджета на привлечении селебрити',
      text: 'Компания использовала нейросеть при производстве рекламы',
      link: '/cases/pyaterochka-sozdala-deepfake-reklamu-na-platforme-ml-space',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/cases/case-4.png',
    },
  ],
  clients: {
    title: 'Помогаем клиентам внедрять инновации',
    slides: [
      {
        id: 'client2',
        image: 'https://cdn.cloud.ru/backend/cases/magnit.png?w=146&h=27',
      },
      {
        id: 'client4',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Dodo.svg?w=126&h=56',
      },
      {
        id: 'client6',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Pyatorochka.svg?w=146&h=56',
      },
      {
        id: 'client8',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Ruspharm.svg?w=126&h=56',
      },
      {
        id: 'client10',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/MoscowBook.svg?w=47&h=56',
      },
      {
        id: 'client11',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Modis.svg?w=103&h=56',
      },
      {
        id: 'client12',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Crafter.svg?w=145&h=56',
      },
      {
        id: 'client14',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Media_Scope.svg?w=50&h=56',
      },
      {
        id: 'client15',
        image:
          'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Rabora.ru.svg?w=133&h=56',
      },
    ],
  },
};

const BENEFITS = {
  title: 'Почему нас выбирают',
  items: [
    {
      icon: <RocketFly />,
      title: 'Все для быстрого старта',
      text: 'Понятная консоль управления сервисами, подробные руководства и документация с пошаговыми инструкциями.',
    },
    {
      icon: <Clouds />,
      title: 'Большой выбор сервисов',
      text: 'Инструменты для разработки и хостинга микросервисных приложений, вычислений, аналитики данных, два суперкомпьютера для работы с AI.',
    },
    {
      icon: <Shield />,
      title: 'Безопасность данных',
      text: 'Сертификаты для работы с персональными данными, ГИС,КИИ и финансовыми операциями.',
    },
    {
      icon: <Datacenter />,
      title: 'Защищенные ЦОД',
      text: 'Шесть дата-центров с уровнем надежности Tier III и SLA 99,982%. Современные серверы и последние версии ПО.',
    },
    {
      icon: <Support />,
      title: 'В поддержке люди, а не боты',
      text: 'Круглосуточная связь с поддержкой по телефону, почте и в Telegram. Среднее время реакции — 15 минут.',
    },
    {
      icon: <Admin />,
      title: 'Команда опытных разработчиков',
      text: '1 400 специалистов работают над запуском наших собственных IaaS- и PaaS-сервисов.',
    },
  ],
};

const BENEFITS_DOCS = {
  title: 'Начните с полезных разделов',
  items: [
    {
      icon: <File />,
      title: 'Документация',
      text: 'Обширная и детализированная документация поможет вам легко ориентироваться в продуктах и услугах',
      slug: '/docs/index.html',
    },
    {
      icon: <Ruble />,
      title: 'Цены',
      text: 'С помощью калькулятора вы сможете точно рассчитать необходимые мощности и оптимизировать свои затраты',
      slug: '/calculator',
    },
  ],
};

const CAROUSEL_DATA = {
  title: 'Компании, которые решили свою задачу с Cloud.ru',
  slides: [
    {
      text: 'Время выгрузки отчета в 1С сократилось в 2 раза. ',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_1.png',
      link: '',
    },
    {
      text: 'Перенос в облако «1C:ERP» и «1С:ЗУП» снизил нагрузку на&nbsp;техподдержку',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_2.png',
      link: '',
    },

    {
      text: 'Данные в «1C:ERP»  стали загружаться быстрее на 5-6 часов.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_3.png',
      link: '',
    },
    {
      text: 'Миграция данных 1С в облако реализована за 24 часа. Все&nbsp;сервисы работают стабильно, в то время как количество данных превышает 1 ТБ.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_4.png',
      link: '',
    },
    {
      text: 'Бизнес-процессы в 1С:Документооборот» ускорились в несколько раз.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_5.png',
      link: '',
    },
    {
      text: 'Повысилась доступность всех учетных систем — «1C:ERP», «1С:ЗУП» и «1С:Бухгалтерия».',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_6.png',
      link: '',
    },
  ],
};

const TABS_DATA = {
  title: 'Удобный личный кабинет',
  tabs: [
    {
      id: '1',
      title: 'Создание инфраструктуры',
    },
    {
      id: '2',
      title: 'Контроль затрат',
    },
    {
      id: '3',
      title: 'Быстрая поддержка',
    },
  ],
  videos: [
    {
      id: '1',
      link: 'https://cdn.cloud.ru/backend/video/evolution-compute/lk.mp4',
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/evo_compute_preview.png',
    },
    {
      id: '2',
      link: 'https://cdn.cloud.ru/backend/video/main-page/cost_control.mov',
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/cost_control_preview.png',
    },
    {
      id: '3',
      link: 'https://cdn.cloud.ru/backend/video/main-page/support.mov',
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/support_preview.png',
    },
  ],
  button: {
    title: 'в консоль',
    uniqueId: 'main-page-tabs-button',
  },
};

const TARIFF_TITLE = 'Начните с популярных сервисов';

const BUTTON_ID = '';

interface IndexProps {
  catalog: Catalog;
  carouselCases: ClientCasesProps[];
  mainSlider: MainSlideProps[];
}

function Index({ catalog, carouselCases, mainSlider }: IndexProps) {
  const [isConsultationFormVisible, setIsConsultationFormVisible] =
    useState(false);

  const router = useRouter();

  const consoleUrl = useFormatConsoleUrl(
    consoleRedirectUrl,
    'body',
    'В консоль',
  );

  return (
    <Layout>
      <Seo
        title="Облако Cloud.ru —  Российская облачная платформа IaaS/PaaS и ML сервисов, облачные услуги от ведущего провайдера"
        description="Cloud.ru (ООО «Облачные технологии») – Облачная платформа IaaS/PaaS и Machine Learning сервисов. Облачные услуги, вычислительные ресурсы, хранение данных в облаке, мониторинг и управление, сервисы для Big Data."
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationMicroData),
          }}
        />
      </Seo>
      <div className={s.root}>
        <MainCarousel slides={mainSlider} />

        <div className={s.tariffWrapper}>
          <TariffPlans tariffPlansType="mainPage" title={TARIFF_TITLE} />
        </div>

        {catalog && (
          <CatalogBlock
            title="Продукты Cloud.ru"
            categories={catalog.categories}
            products={catalog.products}
            withOutTags
          />
        )}

        <DemoSectionWithTabs
          title={TABS_DATA.title}
          tabs={TABS_DATA.tabs}
          videos={TABS_DATA.videos}
          button={{
            title: TABS_DATA.button.title,
            uniqueId: TABS_DATA.button.uniqueId,
            onClick: () => router.push(consoleUrl),
          }}
        />

        {carouselCases && carouselCases.length > 0 && (
          <div className={s.clientCarouselWrapper}>
            <ClientCasesCarousel
              title={CAROUSEL_DATA.title}
              slides={carouselCases}
              className={s.casesCarousel}
            />

            <Wrapper className={s.buttonWrapper}>
              <Button
                variant={Button.variants.Outline}
                size={Button.sizes.Big}
                data-qa="show-more-btn-main-page"
                link="/cases"
                className={s.showMore}
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'button',
                  clickContent: 'смотреть Все кейсы',
                  uniqueId: `cases-show-more-button-main-page`,
                  transitionType: 'inside-link',
                }}
              >
                смотреть Все кейсы
              </Button>
            </Wrapper>
          </div>
        )}

        <Benefits
          className={s.benefits}
          title={BENEFITS.title}
          cards={BENEFITS.items}
          grid={Benefits.grid.ThreeColumns}
        />

        <Benefits
          className={s.benefits}
          title={BENEFITS_DOCS.title}
          cards={BENEFITS_DOCS.items}
          cardWithShadow
        />
      </div>
      {isConsultationFormVisible && (
        <Form
          consultationFormType="body"
          allFormsSentFormType="consultation"
          allFormsSentIsPopup
          tryFormType="consultation"
          title="Связаться с нашим специалистом"
          modalClose={() => setIsConsultationFormVisible(false)}
          inputPath="v2/order"
          additionalFields={{ subsegment: 'consultation' }}
          analyticsFieldFill={{
            formType: 'popup',
            popupPlace: 'body',
            buttonId: BUTTON_ID,
          }}
          formVariant="modal"
          successfulSubmissonFormAnalytics={{
            formname: 'consultation',
          }}
        />
      )}
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = withErrorThrowing(async () => {
  const data = MockIndex;

  const resSolutions = await fetchOrThrow<ResponseCatalog>(
    `${backendHost}/content/v2/catalog?type=solutions&hidden=true`,
  );

  const resCatalog = await fetchOrThrow<ResponseCatalog>(
    `${backendHost}/content/v2/catalog?type=products&hidden=true`,
  );

  const resCases = await fetchOrThrow<ResponseCases>(
    `${backendHost}/content/v1/client_cases`,
  );

  const resMainSlider = await fetchOrThrow<ResponseMainSlider>(
    `${backendHost}/content/v1/main-slider`,
  );

  const promises = [resSolutions, resCatalog, resCases, resMainSlider];

  const allSettledResult = await Promise.allSettled(promises);

  const solutionsRes =
    allSettledResult?.[0].status === 'fulfilled'
      ? (allSettledResult[0].value as ResponseCatalog)
      : undefined;
  const catalogRes =
    allSettledResult?.[1].status === 'fulfilled'
      ? (allSettledResult[1].value as ResponseCatalog)
      : undefined;

  const casesRes =
    allSettledResult?.[2].status === 'fulfilled'
      ? (allSettledResult[2].value as ResponseCases)
      : undefined;

  const mainSliderRes =
    allSettledResult?.[3].status === 'fulfilled'
      ? (allSettledResult[3].value as ResponseMainSlider)
      : undefined;

  const solutionsData =
    solutionsRes?.success &&
    solutionsRes?.data &&
    solutionsRes?.data.products &&
    solutionsRes?.data.products.length > 0
      ? solutionsRes.data
      : null;

  const catalogData =
    catalogRes?.success &&
    catalogRes?.data &&
    catalogRes?.data.categories &&
    catalogRes?.data.products.length > 0
      ? catalogRes.data
      : null;

  const casesData =
    casesRes?.success && casesRes?.data && casesRes?.data.clientCases.length > 0
      ? casesRes.data
      : null;

  const mainSliderData =
    mainSliderRes?.success &&
    mainSliderRes?.data &&
    mainSliderRes?.data.length > 0
      ? mainSliderRes.data
      : null;

  const sortByTitleOrder = (
    titleOrder: string[],
    casesList: Case[] | undefined,
  ) => {
    if (casesList && casesList.length > 0) {
      const sortedCases = [...casesList];

      sortedCases.sort((a, b) => {
        return titleOrder.indexOf(a.slug) - titleOrder.indexOf(b.slug);
      });

      return sortedCases;
    }

    return [];
  };

  const casesSlug = [
    'hoff-perenesla-rezervnye-kopii-sajta-i-prilozheniya-v-oblako-cloud-ru',
    '1s-buhgalteriya-dodo-brands-v-oblake-sbercloud',
    'gwm',
    'set-magnit-perenesla-pochtovyj-servis-v-cloud-ru-i-sokratila-raskhody',
    'klyuchavto-uskoril-rabotu-1s-sistemy-migrirovav-v-cloud',
    'instrument',
  ];

  const carouselCasesRow = casesData?.clientCases.filter(({ slug }) =>
    casesSlug.includes(slug),
  );

  const carouselCases = sortByTitleOrder(casesSlug, carouselCasesRow);

  interface AdaptedCasesProps {
    text: string;
    image: string;
    link: string;
  }

  let adaptedCases: AdaptedCasesProps[] = [];

  if (carouselCases && carouselCases.length > 0) {
    adaptedCases = carouselCases?.reduce(
      (acc: AdaptedCasesProps[], current) => {
        const { title, image, slug } = current;

        const newCase = { text: title, image, link: `/cases/${slug}` };

        return [...acc, newCase];
      },
      [],
    );
  }

  const filteredProducts = catalogData?.products.filter(
    (product) =>
      product.productPlatforms.length > 1 ||
      Boolean(
        product.productPlatforms.find(
          (platform) => platform.slug === 'evolution',
        ),
      ),
  );

  const filteredCategories = catalogData?.categories.filter((category) =>
    filteredProducts?.some((product) =>
      product.categories?.some(
        (productCategory) => productCategory.id === category.id,
      ),
    ),
  );

  const popularCategoryId = filteredCategories?.find(
    (category) => category.slug === 'popular',
  );

  const oneCProductRaw = solutionsData?.products.find(
    (s) => s.slug === 'vse-programmy-1s-na-serverah-cloud',
  );

  const oneCProduct = popularCategoryId
    ? {
        ...oneCProductRaw,
        categories: [popularCategoryId],
        productPlatforms: [{}, {}],
        customLink: `solutions/${oneCProductRaw?.slug}`,
        markTitle:
          'Размещение  1С в высокопроизводительном и защищенном облаке',
      }
    : null;

  const catalogDataWithPlatforms = {
    categories: filteredCategories,
    products:
      filteredProducts && oneCProduct
        ? [...filteredProducts, oneCProduct]
        : filteredProducts,
  };

  return {
    props: {
      data,
      catalog: catalogDataWithPlatforms,
      carouselCases: adaptedCases,
      mainSlider: mainSliderData,
    },
  };
});

export default Index;
