import { PromoTag } from '@snack-uikit/promo-tag';
import { Typography } from '@snack-uikit/typography';
import { type ReactNode } from 'react';
import { ProductTags } from 'src/snack/components/ProductTags';
import { type CatalogProduct } from 'src/types/backendContent';
import { Link } from 'src/uikit/Link';

import { type TagType } from '../../types';
import s from './HeaderCardProduct.module.scss';

const DEFAULT_ICON = '/svg/components/ProductsCatalog/icon.svg';

interface HeaderCardProductProps {
  title: string;
  text?: string;
  link: string;
  icon: string | ReactNode;
  tags?: TagType[];
  target?: '_blank' | '_self';
  productTags?: CatalogProduct['tags'];
  onClickForClose?(): void;
}

export function HeaderCardProduct({
  title,
  text,
  link,
  tags,
  icon,
  target = '_self',
  productTags,
  onClickForClose,
}: HeaderCardProductProps) {
  return (
    <div className={s.root} onClick={onClickForClose}>
      <div className={s.icon}>
        {typeof icon === 'string' ? (
          <img src={icon || DEFAULT_ICON} width={40} height={40} alt={title} />
        ) : (
          icon
        )}
      </div>
      <div className={s.content}>
        <div className={s.header}>
          <div className={s.title}>
            <Link className={s.link} href={link} target={target}>
              <Typography
                tag="span"
                size="l"
                purpose="label"
                family="sans"
                className={s.titleText}
              >
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
            </Link>
          </div>

          {tags &&
            tags.length > 0 &&
            tags.map(({ title, appearance }, index) => {
              return (
                <PromoTag
                  key={`${title}_${index}`}
                  text={title}
                  size="xs"
                  color="decor"
                  appearance={appearance}
                />
              );
            })}
        </div>
        {text && (
          <div className={s.description}>
            <Typography tag="div" size="m" purpose="body" family="sans">
              {text}
            </Typography>
          </div>
        )}
        {Array.isArray(productTags) ? (
          <div className={s.productTags}>
            <ProductTags
              tagClassName={s.productTag}
              tags={productTags}
              length={2}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
