import cn from 'classnames';
import { forwardRef } from 'react';
import { Wrapper } from 'src/components/Wrapper';

import s from '../Form.module.scss';
import { FormBase } from '../FormBase';
import { FormBaseProps } from '../types';

export const InnerForm = forwardRef<HTMLElement, FormBaseProps>(
  ({ id, classNames = {}, ...props }, ref) => {
    return (
      <section ref={ref} className={cn(s.inner, classNames?.inner)} id={id}>
        <Wrapper className={s.wrapper}>
          <FormBase
            {...props}
            successfulSubmissonFormAnalytics={
              props.successfulSubmissonFormAnalytics && {
                zoneclick:
                  props.successfulSubmissonFormAnalytics.zoneclick || 'B',
                formname: props.successfulSubmissonFormAnalytics?.formname,
              }
            }
          />
        </Wrapper>
      </section>
    );
  },
);

InnerForm.displayName = 'InnerForm';
