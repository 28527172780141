import cn from 'classnames';
import { ReactNode } from 'react';
import { ArrowOut } from 'src/uikit/icons/ContentIcons/General/ArrowOut';
import { Link } from 'src/uikit/Link';

import { RichText } from '../../../../components/RichText';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { TargetLinkType } from '../../constants';
import { CardProps } from '../../types';
import s from './PrimaryCard.module.scss';

type CardImageWrapperType = {
  icon: string | ReactNode;
  imageAlt: string;
};

function CardIconWrapper({ icon, imageAlt }: CardImageWrapperType) {
  return (
    <div className={s.cardImageWrapper}>
      {typeof icon === 'string' ? (
        <img
          src={icon}
          width={40}
          height={40}
          alt={imageAlt}
          className={s.cardImage}
        />
      ) : (
        icon
      )}
    </div>
  );
}

export function PrimaryCard({
  title,
  text,
  icon,
  slug,
  cardWithShadow = false,
  cardWithNumeric = false,
  alignContent = 'top',
  target = TargetLinkType.Self,
  isNumericBlack = false,
  withArrow = true,
  hover,
}: CardProps) {
  const { contactInteractionAnalytics } = useAnalytics();

  if (!slug) {
    return (
      <div
        className={cn(s.card, s[alignContent], {
          [s.shadow]: cardWithShadow,
          [s.numeric]: cardWithNumeric,
          [s.numericBlack]: isNumericBlack,
        })}
      >
        <CardIconWrapper icon={icon} imageAlt={title} />

        <div className={s.cardDescription}>
          {title && (
            <h4
              className={s.cardTitle}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {text && <RichText richText={text} />}
        </div>
      </div>
    );
  }

  const handleCopy = () => {
    if (slug.startsWith('tel:')) {
      contactInteractionAnalytics({
        interactionType: 'copy',
        contact: 'phone',
        interactionText: title,
      });
    }
    if (slug.startsWith('mailto:')) {
      contactInteractionAnalytics({
        interactionType: 'copy',
        contact: 'email',
        interactionText: title,
      });
    }
  };

  const handleClick = () => {
    if (slug.startsWith('tel:')) {
      contactInteractionAnalytics({
        interactionType: 'click',
        contact: 'phone',
        interactionText: title,
      });
    }
    if (slug.startsWith('mailto:')) {
      contactInteractionAnalytics({
        interactionType: 'click',
        contact: 'email',
        interactionText: title,
      });
    }
  };

  return (
    <Link
      href={slug}
      target={target}
      className={cn(s.card, s.cardLink, {
        [s.shadow]: cardWithShadow,
        [s.alignContentMiddle]: alignContent,
        [s.hoverOnlyTitle]: hover === 'onlyTitle',
      })}
      analyticsOptions={{
        action: slug,
        clickZone: 'body',
        clickElement: 'cardclick',
        clickContent: title,
        uniqueId: `benefits-link-${title}`,
        transitionType: 'inside-link',
      }}
      onCopy={handleCopy}
      onClick={handleClick}
    >
      <CardIconWrapper icon={icon} imageAlt={title} />

      <div className={s.cardDescription}>
        <h4 className={s.cardTitle}>
          {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
          {withArrow && <ArrowOut className={s.cardTitleIcon} />}
        </h4>

        {text && <RichText richText={text} />}
      </div>
    </Link>
  );
}
