import cn from 'classnames';
import { useState } from 'react';
import { RichText } from 'src/components/RichText';
import { VideoPlayer } from 'src/components/VideoPlayer';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Button } from 'src/uikit/Button';
import { Tabs } from 'src/uikit/Tabs';

import { DemoSectionWithTabsProps } from '../types';
import s from './DemoSectionWithTabs.module.scss';

function ImageView({
  image,
  title,
  subtitle,
}: {
  image?: string;
  title?: string;
  subtitle?: string;
}) {
  return (
    <>
      {image && (
        <div className={s.image}>
          <img src={image} alt="img" />
        </div>
      )}
      {(title || subtitle) && (
        <div className={s.imageText}>
          {title && <div className={s.imageTitle}>{title}</div>}
          {subtitle && (
            <div
              className={s.imageSubtitle}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
        </div>
      )}
    </>
  );
}

export function DemoSectionWithTabs({
  title,
  tabs,
  videos,
  images,
  richTexts = [],
  id,
  className = '',
  button,
}: Omit<
  DemoSectionWithTabsProps,
  'image' | 'content' | 'video' | 'contentLeft'
> & {
  title: string;
  id?: string;
}) {
  // TODO: поменять на tabs[0].id ?
  const [activeTab, setActiveTab] = useState<string>('1');

  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <section id={id} className={cn(s.root, className)}>
      {title && (
        <Wrapper className={s.wrapper}>
          <h2 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
        </Wrapper>
      )}
      {windowWidth && windowWidth <= MD && (
        <Tabs
          className={s.tabs}
          tabs={tabs}
          activeTab={activeTab}
          onClick={(tab) => setActiveTab(tab.id)}
          classNameContainer={s.tabsWrapper}
          variant="horizontal"
        />
      )}
      <Wrapper className={s.wrapper}>
        <div className={s.content}>
          {windowWidth && windowWidth > MD && (
            <div className={s.contentLeft}>
              <Tabs
                className={s.tabs}
                tabs={tabs}
                activeTab={activeTab}
                onClick={(tab) => setActiveTab(tab.id)}
                variant="vertical"
              />
              {button && (
                <Button
                  className={s.button}
                  onClick={button.onClick}
                  analyticsOptions={{
                    action: 'click',
                    clickZone: 'body',
                    clickElement: 'button',
                    clickContent: button.title,
                    uniqueId: button.uniqueId,
                    transitionType: 'inside-link',
                  }}
                >
                  {button.title}
                </Button>
              )}
            </div>
          )}

          <div>
            {videos &&
              !images &&
              videos.map(
                ({ id, link, previewImage, controls }) =>
                  id === activeTab && (
                    <VideoPlayer
                      link={link}
                      key={id}
                      withOutError
                      muted={!controls}
                      loop={!controls}
                      controls={controls}
                      previewImage={previewImage}
                      dataQa="section_video"
                    />
                  ),
              )}

            {images &&
              !videos &&
              images.map(
                ({ id, image, title, subtitle }, index) =>
                  id === activeTab && (
                    <ImageView
                      key={`${image}_${index}`}
                      image={image}
                      title={title}
                      subtitle={subtitle}
                    />
                  ),
              )}

            {richTexts.map(
              ({ id, richText }) =>
                id === activeTab && (
                  <RichText
                    key={`richText_${id}`}
                    richText={richText}
                    className={s.richText}
                  />
                ),
            )}
          </div>

          {button && windowWidth && windowWidth < MD && (
            <Button
              className={s.button}
              onClick={button.onClick}
              analyticsOptions={{
                action: 'click',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: button.title,
                uniqueId: button.uniqueId,
                transitionType: 'inside-link',
              }}
            >
              {button.title}
            </Button>
          )}
        </div>
      </Wrapper>
    </section>
  );
}
