import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useSubmitForm } from 'src/hooks/useSubmitForm';
import { useTryFormErrorsCheck } from 'src/hooks/useTryFormErrorsCheck';
import { FormAdditionalMaterials } from 'src/snack/components/Form/FormAdditionalMaterials';
import { FormNoticeBlock as SnackFormNoticeBlock } from 'src/snack/components/Form/FormNotice';
import { DataFromFormInputs } from 'src/types';

import { FormForEnum, ResultCodes } from '../constants';
import s from '../Form.module.scss';
import { FormDefault } from '../FormDefault';
import { FormEducation } from '../FormEducation';
import { FormNoticeBlock } from '../FormNoticeBlock';
import { FormBaseProps, SolutionsProps } from '../types';

function LoadingIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.loadingIcon}
    >
      <g id="loader">
        <path
          id="Ellipse 2438 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3636 20C36.3636 10.9626 29.0374 3.63636 20 3.63636V0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40V36.3636C29.0374 36.3636 36.3636 29.0374 36.3636 20Z"
          fill="#26D07C"
        />
      </g>
    </svg>
  );
}

export function FormBase({
  title,
  subtitle,
  inputPath,
  short = false,
  legalEntity = false,
  formVariant,
  successNotice = {
    title: 'Мы приняли вашу заявку, скоро с вами свяжется специалист.',
  },
  errorNotice = {
    buttonTitle: 'Обновить страницу',
  },
  buttonTitle = 'Отправить',
  webinarsFields,
  fieldsNames,
  productSlug,
  platformSlug,
  agreementName,
  marketingEventIDs,
  ispringCourseId,
  additionalFields,
  dataLayer,
  analyticsFieldFill,
  tryFormType,
  allFormsSentFormType,
  allFormsSentIsPopup,
  consultationFormType,
  saleFormType,
  multipleComment,
  withLoader,
  withInn = false,
  formFor = FormForEnum.Default,
  withSubscribeForMailing = true,
  successfulSubmissonFormAnalytics,
  isSubscribeForMailingRequired = false,
}: FormBaseProps) {
  const dataFields = {
    fields: 'ba68da97a4162765cbb785d623b81814d0d1d00c4b61560f923090d1c83f56aa',
    leadSource:
      'b4137ddb2a845a933ebf6674d9afebc28d6bb532d15e25a26c2135130f378c7d',
    subsegment:
      'cf2cb923cc6a1ba8c56db485216355f94ff3aa4fff2880951382bdaf834e03a6',
    priority:
      '3092a5f0dba05ff3cadc3c2c8eeacd47940b1837f9534bc559d19d8435c8b219',
  };

  const formMethods = useForm<DataFromFormInputs>({
    mode: 'onChange',
    defaultValues: {
      userAgreement: true,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setError,
  } = formMethods;

  const {
    fillFormField,
    tryFormAnalytics,
    consultationFormSent,
    saleFormSent,
  } = useAnalytics();
  const isPhisic = !!watch('isNaturalPerson', false);
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { submitForm, formStatus, setFormStatus, resultCode, formMessage } =
    useSubmitForm();
  const [showNotice, setShowNotice] = useState(true);
  const { successfullSubmissionFormAnalytics } = useAnalytics();

  useTryFormErrorsCheck(errors, isSubmitting, tryFormType);

  useEffect(() => {
    if (
      formStatus === 'error' &&
      resultCode === ResultCodes.EmailAlreadyExist
    ) {
      setError('email', {
        type: 'manual',
        message: formMessage,
      });
      setShowNotice(false);
    } else {
      setShowNotice(true);
    }
  }, [formStatus]);

  const sendFormFillAnalytics = (fieldName: string) => {
    const { formType, popupPlace, buttonId } = analyticsFieldFill;
    if (formType === 'body') {
      fillFormField({
        formType: 'body',
        fieldName: fieldName,
      });
    }
    if (popupPlace && buttonId) {
      fillFormField({
        formType: 'popup',
        fieldName: fieldName,
        popupPlace: popupPlace,
        buttonId: buttonId,
      });
    }

    return null;
  };

  const onSubmit = async (data: DataFromFormInputs) => {
    let comments = null;

    if (Array.isArray(data.comment)) {
      const commentsData = data.comment as SolutionsProps[];

      comments = commentsData?.map(({ label }) => label).join('. ');
    }

    if (formStatus === 'sending') return;
    data = {
      ...data,
      ...webinarsFields,
      marketingEventIDs,
      ispringCourseId,
      referer: document.referrer,
      isNaturalPerson: !!data.isNaturalPerson,
      subscribeForMailing: !!data.subscribeForMailing,
      comment: comments ?? data.comment,
    };

    if (productSlug) {
      data.productSlug = productSlug;
    }

    if (platformSlug) {
      data.platformSlug = platformSlug;
    }

    if (additionalFields) {
      const elmaFields: { [key: string]: string } = {};
      let key: keyof typeof additionalFields;

      for (key in additionalFields) {
        if (additionalFields[key]) {
          elmaFields[dataFields[key]] = additionalFields[key]!;
        }
      }

      data[dataFields.fields] = elmaFields;
    }

    if (data) {
      let other_consents;

      switch (agreementName) {
        case 'online_event_consent':
          delete data.online_event_consent;
          other_consents = ['online_event_consent'];
          break;
        case 'education_consent':
          delete data.education_consent;
          other_consents = ['education_consent'];
          break;
        default:
          delete data.site_services_consent;
          other_consents = ['site_services_consent'];
      }

      await submitForm({
        backendPatchInput: `/forms/${inputPath}`,
        allFormSentFormType: allFormsSentFormType,
        allFormSentIsPopup: allFormsSentIsPopup,
        dataFromFormInputs: {
          ...data,
          other_consents,
        },
        onSuccess: () => {
          tryFormAnalytics({ formType: tryFormType, action: 'Отправлено' });
          if (consultationFormType) {
            consultationFormSent(
              consultationFormType,
              !!data.subscribeForMailing,
            );
          }
          if (saleFormType)
            saleFormSent(
              saleFormType.type,
              !!data.subscribeForMailing,
              saleFormType.platformName,
            );
          sendDataToDataLayer({
            dataLayer,
          });
          successfulSubmissonFormAnalytics &&
            successfullSubmissionFormAnalytics(
              successfulSubmissonFormAnalytics,
            );
          reset();
        },
        onError: () => {
          tryFormAnalytics({
            formType: tryFormType,
            action: 'Ошибка',
            errorCode: resultCode,
          });
        },
      });
    }
  };

  const formErrorNotice = {
    ...errorNotice,
    title: errorNotice?.title ? errorNotice?.title : formMessage,
    buttonTitle: errorNotice?.buttonTitle
      ? errorNotice?.buttonTitle
      : 'Попробовать еще раз',
  };

  const formSuccessNotice = {
    ...successNotice,
    title: successNotice?.title ? successNotice?.title : formMessage,
  };

  const analyticsButtonId =
    analyticsFieldFill.formType === 'popup'
      ? `modal-${analyticsFieldFill.popupPlace}`
      : analyticsFieldFill.formType;

  const isSendingStatus = withLoader && formStatus === 'sending';
  const showLoader =
    isSendingStatus && formFor !== FormForEnum.AdditionalMaterials;

  const getForm = () => {
    switch (formFor) {
      case FormForEnum.OfflineEvent:
      case FormForEnum.Education:
        return {
          content: (
            <FormEducation
              isPhysic={isPhisic}
              buttonTitle={buttonTitle}
              sendFormFillAnalytics={sendFormFillAnalytics}
              fieldsNames={fieldsNames}
              agreementName={agreementName}
              legalEntity={legalEntity}
              multipleComment={multipleComment}
              short={short}
            />
          ),
          noticeBlock: (
            <FormNoticeBlock
              formStatus={formStatus}
              successNotice={formSuccessNotice}
              analyticsButtonId={analyticsButtonId}
              errorNotice={formErrorNotice}
              setFormStatus={setFormStatus}
              formVariant={formVariant}
              showNotice={showNotice}
            />
          ),
        };
      case FormForEnum.AdditionalMaterials:
        return {
          content: (
            <FormAdditionalMaterials
              sendFormFillAnalytics={sendFormFillAnalytics}
              agreementName={agreementName}
              buttonTitle={buttonTitle}
              fieldsNames={fieldsNames}
              withSubscribeForMailing={withSubscribeForMailing}
              isSendingStatus={isSendingStatus}
            />
          ),
          noticeBlock: (
            <SnackFormNoticeBlock
              formStatus={formStatus}
              successNotice={formSuccessNotice}
              analyticsButtonId={analyticsButtonId}
              errorNotice={formErrorNotice}
              setFormStatus={setFormStatus}
              formVariant={formVariant}
              showNotice={showNotice}
            />
          ),
        };
      default:
        return {
          content: (
            <FormDefault
              isPhysic={isPhisic}
              sendFormFillAnalytics={sendFormFillAnalytics}
              buttonTitle={buttonTitle}
              short={short}
              multipleComment={multipleComment}
              legalEntity={legalEntity}
              agreementName={agreementName}
              fieldsNames={fieldsNames}
              withSubscribeForMailing={withSubscribeForMailing}
              isSubscribeForMailingRequired={isSubscribeForMailingRequired}
              withInn={withInn}
            />
          ),
          noticeBlock: (
            <FormNoticeBlock
              formStatus={formStatus}
              successNotice={formSuccessNotice}
              analyticsButtonId={analyticsButtonId}
              errorNotice={formErrorNotice}
              setFormStatus={setFormStatus}
              formVariant={formVariant}
              showNotice={showNotice}
            />
          ),
        };
    }
  };

  const formInner = getForm();

  return (
    <div className={s.formWithNotice}>
      <div
        className={cn(s.formWrapper, {
          [s.short]: short,
          [s.modal]: formVariant === 'modal',
          [s.hidden]:
            formStatus !== 'idle' && formStatus !== 'sending' && showNotice,
        })}
      >
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-qa="section_form"
            className={cn(s.form, { [s.isSending]: showLoader })}
          >
            <div className={s.header}>
              {title && (
                <Typography family="sans" purpose="headline" size="l" tag="h2">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography family="sans" purpose="body" size="l">
                  {subtitle}
                </Typography>
              )}
            </div>

            {formInner.content}
          </form>
        </FormProvider>

        {showLoader && <LoadingIcon />}
      </div>

      {formInner.noticeBlock}
    </div>
  );
}
